<template>
  <div>
    <div class="card">
      <Toast position="top-center" group="info" />
      <h5>Companies</h5>
      <ProgressBar
        v-if="loading"
        class="progress-bar"
        mode="indeterminate"
      />
      <Button
        v-else
        label="Add a company"
        class="p-button-primary"
        @click="showCompanyDialog(null)"
      />
      <DataTable
        v-if="!loading"
        :value="companyList"
        data-key="id"
        v-model:wallets="companyList"
        responsiveLayout="scroll"
        paginator
        lazy
        :rows="rows"
        :totalRecords="totalItems"
        @page="setPage"
      >
        <Column header="Company" field="nameRus" />
        <Column header="Country" field="countryCode" />
        <Column header="Verification" field="isVerified">
          <template #body="slot">
            {{ slot.data.isVerified ? 'Passed' : 'Absent' }}
          </template>
        </Column>
        <Column header="Request" field="isVerifyRequest">
          <template #body="slot">
            {{ slot.data.isVerifyRequest ? 'Sent' : 'Is not sent' }}
          </template>
        </Column>
        <Column
          class="bg-white border-none flex flex-nowrap justify-content-end"
          style="width: 130px"
        >
          <template #body="slot">
            <div class="for-two-btn-container">
              <div class="absolute flex gap-2">
                <Button
                  :disabled="slot.data.isVerifyRequest"
                  icon="pi pi-pencil"
                  class="p-button-rounded p-button-warning m-1"
                  @click="showCompanyDialog(slot.data)"
                />
                <Button
                  icon="pi pi-trash"
                  class="p-button-rounded p-button-danger m-1"
                  @click="showDeleteDialog(slot.data)"
                />
              </div>
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
    <!------------------- Create or Edit Dialogs ------------------->
    <Dialog
      v-model:visible="createEditDialog"
      :header="header"
      style="width: 700px"
      class="p-fluid"
    >
      <div class="flex">
        <div class="mr-3 w-6">
          <label for="nameRus"><b>Company name (in Russian)(*)</b></label>
          <InputText id="nameRus" class="mb-2" v-model="nameRus" />
          <label for="descriptionRus">Field of activity (Russian)</label>
          <Textarea
            id="descriptionRus"
            class="mb-2"
            v-model="descriptionRus"
            :autoResize="true"
          />
          <label for="addressRus"><b>Address (Russian)(*)</b></label>
          <InputText id="addressRus" class="mb-2" v-model="addressRus" />
          <label for="country"><b>Country(*)</b></label>
          <Dropdown
            id="country"
            class="mb-2"
            v-model="country"
            :options="countryList"
            optionLabel="name"
            :filter="true"
            :showClear="true"
          />
          <label for="innRus">INN (for Russia)</label>
          <InputText id="innRus" class="mb-2" v-model="innRus" />
          <label for="kppRus">KPP (for Russia)</label>
          <InputText id="kppRus" v-model="kppRus" />
        </div>
        <div class="w-6">
          <label for="nameEng">Company name(in English)</label>
          <InputText id="nameEng" class="mb-2" v-model="nameEng" />
          <label for="descriptionEng">Field of activity (eng.)</label>
          <Textarea
            id="descriptionEng"
            class="mb-2"
            v-model="descriptionEng"
            :autoResize="true"
          />
          <label for="addressEng">Address (eng.)</label>
          <InputText id="addressEng" class="mb-2" v-model="addressEng" />
          <label for="url">Company website URL</label>
          <InputText id="url" class="mb-2" v-model="url" />
          <label for="ogrnRus">OGRN (for Russia)</label>
          <InputText id="ogrnRus" class="mb-2" v-model="ogrnRus" />
          <label for="bankRus">Bank details (for Russia)</label>
          <InputText id="bankRus" v-model="bankRus" />
        </div>
      </div>
      <div class="mt-3">
        <Checkbox
          id="isVerifyRequest"
          v-model="isVerifyRequest"
          :binary="true"
        />
        <label for="isVerifyRequest">
          Company verification request. Data editing will not be available
        </label>
      </div>
      <div class="mt-2">
        <Checkbox id="isVerified" v-model="isVerified" :binary="true" />
        <label for="isVerified"> Company confirmation </label>
      </div>
      <small v-if="!needField" class="block my-1 p-error">
        Fill in all required fields (with an asterisk *)
      </small>
      <small v-else-if="!goodEng" class="block my-1 p-error">
        English-language information should be in English only
      </small>
      <small v-else-if="!goodUrl" class="block my-1 p-error">
        Incorrect URL
      </small>
      <Button
        class="mt-2 p-button-primary"
        :label="isVerifyRequest ? header : 'Save as draft'"
        :disabled="!(needField && goodEng && goodUrl)"
        @click="addOrEdit"
      />
    </Dialog>
    <!------------------- Delete Dialog ------------------->
    <Dialog
      v-model:visible="deleteDialog"
      header="Delete company?"
      :modal="true"
    >
      <div class="confirmation-content">
        <p class="text-center p-error" v-if="itemEdit">
          Are you sure you want to delete?
        </p>
        <p class="text-center">
          <b>{{ modifyCompany.nameRus }}</b>
        </p>
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          class="p-button-text"
          @click="deleteDialog = false"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteCompany"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import countries from '@/countries/en'
export default {
  data() {
    return {
      loading: false,
      companyList: [],
      countryList: [],
      createEditDialog: false,
      deleteDialog: false,
      modifyCompany: null,
      nameRus: null,
      nameEng: null,
      descriptionRus: null,
      descriptionEng: null,
      addressRus: null,
      addressEng: null,
      country: null,
      url: null,
      isVerifyRequest: false,
      isVerified: false,
      innRus: null,
      ogrnRus: null,
      kppRus: null,
      bankRus: null,
      page: 1,
      itemsPerPage: 30,
      rows: 30,
      totalItems: 10,
    }
  },

  async mounted() {
    await this.getCompany()
    this.countryList = countries;
  },

  computed: {
    header() {
      return this.modifyCompany === null
        ? 'Add a company'
        : 'Edit company'
    },

    needField() {
      return (
        this.nameRus !== null &&
        this.addressRus !== null &&
        this.country !== null
      )
    },

    goodEng() {
      return (
        (this.nameEng === null ||
          this.nameEng === undefined ||
          this.$filters.languageEN(this.nameEng)) &&
        (this.descriptionEng === null ||
          this.descriptionEng === undefined ||
          this.$filters.languageEN(this.descriptionEng)) &&
        (this.addressEng === null ||
          this.addressEng === undefined ||
          this.$filters.languageEN(this.addressEng))
      )
    },

    goodUrl() {
      return (
        this.url === null ||
        this.url === undefined ||
        this.$filters.correctURL(this.url)
      )
    },
  },

  methods: {
    setPage(event) {
      this.page = event.page + 1
      this.getCompany()
    },
    async getCompany() {
      this.loading = true;
      const headers = {
        accept: 'application/ld+json',
        'Content-Type': 'application/ld+json',
      }
      const params = {
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        pagination: true,
      }
      try {
        const resp = await this.axios.get('api/companies', { headers, params })
        this.companyList = resp.data['hydra:member']
        this.totalItems = resp.data['hydra:totalItems']
      } catch (e) {
        this.showError('Error in api/companies api call')
      }
      this.loading = false;
    },

    async addCompany() {
      this.createEditDialog = false
      const params = {
        nameRus: this.nameRus,
        nameEng: this.nameEng,
        descriptionRus: this.descriptionRus,
        descriptionEng: this.descriptionEng,
        addressRus: this.addressRus,
        addressEng: this.addressEng,
        country: '/api/countries/' + this.country.id,
        url: this.url,
        isVerifyRequest: this.isVerifyRequest,
        innRus: this.innRus,
        ogrnRus: this.ogrnRus,
        kppRus: this.kppRus,
        bankRus: this.bankRus,
      }
      const headers = {
        accept: 'application/json',
        'Content-Type': 'application/json',
      }
      try {
        const resp = await this.axios.post('api/companies', params, headers)
        this.showMessage('Company created')
        let newCompany = resp.data
        newCompany.displayId = this.companyList.length
        this.companyList.push(newCompany)
      } catch (e) {
        this.showError('Error in add company api calls')
      }
    },

    async editCompany() {
      this.createEditDialog = false
      const params = {
        nameRus: this.nameRus,
        nameEng: this.nameEng,
        descriptionRus: this.descriptionRus,
        descriptionEng: this.descriptionEng,
        addressRus: this.addressRus,
        addressEng: this.addressEng,
        country: '/api/countries/' + this.country.id,
        url: this.url,
        isVerifyRequest: this.isVerifyRequest,
        innRus: this.innRus,
        ogrnRus: this.ogrnRus,
        kppRus: this.kppRus,
        bankRus: this.bankRus,
      }
      const headers = {
        accept: 'application/json',
        'Content-Type': 'application/json',
      }
      try {
        const resp = await this.axios.put(
          'api/companies/' + this.modifyCompany.id,
          params,
          headers
        )
        this.showMessage('Company edited')
        let editCompany = resp.data
        editCompany.displayId = this.modifyCompany.displayId
        this.companyList[this.modifyCompany.displayId] = editCompany
      } catch (e) {
        this.showError('Error in add company api calls')
      }
    },

    addOrEdit() {
      if (this.modifyCompany === null) this.addCompany()
      else this.editCompany()
    },

    async deleteCompany() {
      this.deleteDialog = false
      try {
        await this.axios.delete('api/companies/' + this.modifyCompany.id)
        this.showMessage('Company successfully deleted')
        this.companyList.splice(this.modifyCompany.displayId, 1)
      } catch (e) {
        this.showError('Error in delete company api calls')
      }
    },

    showCompanyDialog(data) {
      this.modifyCompany = data
      if (data === null) this.clearFields()
      else this.setFields(data)
      this.createEditDialog = true
    },

    showDeleteDialog(data) {
      this.modifyCompany = data
      this.deleteDialog = true
    },

    clearFields() {
      this.nameRus = null
      this.nameEng = null
      this.descriptionRus = null
      this.descriptionEng = null
      this.addressRus = null
      this.addressEng = null
      this.country = null
      this.url = null
      this.isVerifyRequest = false
      this.isVerified = false
      this.innRus = null
      this.ogrnRus = null
      this.kppRus = null
      this.bankRus = null
    },

    setFields(data) {
      this.nameRus = data.nameRus
      this.nameEng = data.nameEng
      this.descriptionRus = data.descriptionRus
      this.descriptionEng = data.descriptionEng
      this.addressRus = data.addressRus
      this.addressEng = data.addressEng
      this.country = this.countryList.find(item => item.code == data.countryCode)
      this.url = data.url
      this.isVerifyRequest = data.isVerifyRequest
      this.isVerified = data.isVerified
      this.innRus = data.innRus
      this.ogrnRus = data.ogrnRus
      this.kppRus = data.kppRus
      this.bankRus = data.bankRus
    },

    showError(message) {
      this.$toast.add({
        severity: 'error',
        summary: 'Error',
        detail: message,
        group: 'info',
        life: 3000,
      })
    },

    showMessage(message) {
      this.$toast.add({
        severity: 'success',
        summary: 'Successful',
        detail: message,
        group: 'info',
        life: 3000,
      })
    },
  },
}
</script>

<style scoped></style>
